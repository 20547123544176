<template>
  <button class="wy-button" @click="goLogin">注册/登录</button>
</template>

<script setup>
import {getCurrentInstance} from "vue";
import {useRoute, useRouter} from "vue-router";

const route = useRoute();
const router = useRouter();
const {proxy} = getCurrentInstance();
const goLogin = () => {
  //记录当前页面
  router.push({
    path: "/shop/login",
  })
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "loginBtn",
});
</script>

<style lang="scss" scoped>

</style>