<template>
  <div class="concat-desc cur_p"><span @click="handleClick">联系我们</span>
    <!--    <concat-d-ia v-model="showDia" value="我们的企业微信"></concat-d-ia>-->
    <ConcatModal v-model="showDia"/>
  </div>

</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import ConcatDIa from "@views/shop/components/concatDIa/index.vue";
import ConcatModal from "@views/shop/components/concatModal.vue";
import {useRoute} from "vue-router";

const route = useRoute();
const showDia = ref(false)
const {proxy} = getCurrentInstance();
const handleClick = () => {
  if (route.path === '/shop/knowMore') {
    let dom = document.getElementById('concatUs')
    dom.scrollIntoView({
      behavior: "smooth", // 定义动画过渡效果， "auto"或 "smooth" 。默认为 "auto"。
      block: "start", // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"。默认为 "start"。
      inline: "center", // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"。默认为 "nearest"
    })
  } else {
    showDia.value = true
  }
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "concat",
});
</script>

<style lang="scss" scoped>
.concat-desc {
  color: #fff;
  font-size: 14px;
  display: inline-block;
}
</style>
