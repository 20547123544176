<template>
  <teleport to="body">
    <div class="shop-dialog-wy" v-show="modelValue">
      <div class="fixed-content">
        <div class="contact-box">
          <div class="contact-way">
            <div class="title">联系我们</div>
            <div class="way">
              <div class="item">
                <img src="@img/shop/h5/concat-wechat.png" alt="位元灵感"/>
                <div class="val">
                  <div class="t">企业微信：</div>
                  <div class="wechat">
                    <img src="@img/shop/index/wechatPic.jpg" alt="位元灵感"/>
                    <div class="text">请扫描添加客服微信</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="contact-form">
            <div class="content">
              <el-icon class="close-icon cur_p" @click="closeDia">
                <Close/>
              </el-icon>
              <div class="form-title">请留下您的联系方式，客服稍后与您联系</div>
              <div class="form-item" :class="tabChecked === 0?'active':''">
                <div class="name">联系电话</div>
                <input type="tel" maxlength="11" class="wy-input" v-model="form.phone" @focus="tabCheck(0)"
                       @blur="blurCheck"
                       placeholder="请输入您的联系电话"/>
              </div>
              <div class="form-item" :class="tabChecked === 1?'active':''">
                <div class="name">微信</div>
                <input type="text" class="wy-input" maxlength="50" v-model="form.weChat" @focus="tabCheck(1)"
                       @blur="blurCheck" placeholder="请输入您的微信号"/>
              </div>
              <div class="form-item" :class="tabChecked === 2?'active':''">
                <div class="name">如何称呼</div>
                <input type="text" class="wy-input" maxlength="50" v-model="form.nickName" @focus="tabCheck(2)"
                       @blur="blurCheck" placeholder="请输入您的姓名"/>
              </div>
              <div class="form-submit">
                <button class="wy-button primary" @click="subMit">提交</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup>
import {getCurrentInstance, reactive, ref, toRefs, watch} from "vue";
import {contactUs} from "@utils/api/shop";
import {ElMessageBox} from "element-plus";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  value: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['update:modelValue'])
const {modelValue, value} = toRefs(props)
const tabChecked = ref(-1)
const tabCheck = (e) => {
  if (e !== tabChecked.value) tabChecked.value = e;
}
const blurCheck = (e) => {
  tabChecked.value = -1
}
const form = reactive({
  phone: "",
  weChat: "",
  nickName: ""
})
const subMit = () => {
  if (!form.phone) {
    proxy.$message.error('请输入您的联系电话')
    return
  }
  if (!form.nickName) {
    proxy.$message.error('请填写您的称呼')
    return
  }
  //判断手机号是否正确
  if (!(/^1[3456789]\d{9}$/.test(form.phone))) {
    proxy.$message.error('请输入正确的手机号')
    return
  }
  contactUs({
    mobile: form.phone,
    wechat: form.weChat,
    name: form.nickName
  }).then((res) => {
    if (res.code !== 0) return proxy.$message.error(res.msg)
    ElMessageBox.confirm("提交成功！", "提示", {
      confirmButtonText: "确认",
      showCancelButton: false,
      customClass: "zh-message-box shop",
    }).then(() => {
      form.phone = ""
      form.weChat = ""
      form.nickName = ""
    });
  })
}
const {proxy} = getCurrentInstance();
const closeDia = () => {
  emit('update:modelValue', !modelValue.value)
}
watch(() => modelValue.value, (val) => {
  if (val) {
    document.body.style.height = '100vh'
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.height = 'auto'
    document.body.style.overflow = 'auto'
  }
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "concatDIa",
});
</script>

<style lang="scss" scoped>
.shop-dialog-wy {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  overflow: auto;

  .fixed-content {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    display: flex;
  }

  .contact-box {
    width: 1200px;
    height: 680px;
    display: flex;
    justify-content: space-between;
    border-radius: 16px;
    overflow: hidden;
    margin: auto;

    .contact-way {
      width: 400px;
    }

    .contact-form {
      flex: auto;
    }

    .contact-way {
      background-color: #F7F8FA;
      padding: 77px 0 0 72px;
      height: 46vw;
      max-height: 800px;
      min-height: 600px;

      .title {
        font-size: 48px;
        color: $shopFsColor;
        font-weight: 600;
        line-height: 1;
      }

      .way {
        margin-top: 110px;

        .item {
          display: flex;
          align-items: flex-start;
          > img {
            width: 24px;
            height: 20px;
            margin-right: 16px;
          }
          .val {
            color: #1C1D1F;;

            .t {
              font-size: 14px;
              line-height: 1;
            }

            .b {
              font-size: 18px;
              font-weight: 600;
              line-height: 1;
              margin-top: 22px;
            }

            .wechat {
              width: 176px;
              height: 176px;
              padding: 14px;
              background-color: #fff;
              border-radius: 8px;
              margin-top: 22px;
              position: relative;
              left: -40px;

              .text {
                font-size: 14px;
                color: rgba($color:#1C1D1F, $alpha:0.6);
                margin-top: 30px;
                position: relative;
                left: -16px;
              }

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }

    .contact-form {
      background-color: #fff;
      height: 100%;
      position: relative;
      padding:89px 0 0 167px;
      .bg-text {
        font-weight: 600;
        position: absolute;
        top: 50px;
        color: #E0E0E0;
        opacity: 0.3;
        left: 0;
        font-size: 100px;
      }

      .close-icon {
        position: absolute;
        top: 86px;
        right: 72px;
        font-size: 30px;
        color: #AAAAB1;
        font-weight: 600;
        cursor: pointer;
      }

      .content {
        width: 560px;

        .form-title {
          font-size: 24px;
          color: $shopFsColor;
          margin-bottom: 80px;
        }

        .form-item {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #E0E0E0;
          padding: 16px 0;

          &:not(:last-of-type) {
            margin-bottom: 60px;
          }

          &.active {
            border-bottom: 1px solid $mainColor;

            > .name {
              color: $mainColor;
            }
          }

          > .name {
            color: $shopFsColor;
            width: 150px;
            font-size: 18px;
          }

          .wy-input {
            font-size: 18px;
            flex: auto;
            color: #1C1D1F;

            &::placeholder {
              color: #C4C4CC;
            }

          }
        }

        .form-submit {
          text-align: right;

          button {
            width: 200px;
            height: 56px;
            line-height: 56px;
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
