<template>
  <div class="shop-pop-index" v-clickOutside="closePop">
    <div @click="handleClickPop">
      <slot>
      </slot>
    </div>
    <div class="pop-article" :class="showPop?'active':''">
      <div class="shop-pop">
        <slot name="content">
        </slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";

const showPop = ref(false);
const {proxy} = getCurrentInstance();

const handleClickPop = () => {
  showPop.value = !showPop.value;
};
const closePop = () => {
  if (!showPop.value) return
  showPop.value = false;
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "shopPop",
});
</script>

<style lang="scss" src="./index.scss" scoped></style>
