import request from "@utils/http";
import {managerUrl} from "@utils/api";

export function contactUs(data) {
    return request({
        url: managerUrl + "/carton/shop/info/contact",
        method: 'post',
        data
    })
}

//商城版本，产品列表
export function shopGoodsList(data) {
    return request({
        url: managerUrl + "/carton/shop/goods/list",
        method: 'post',
        data
    })
}

//优惠码校验
export function checkCoupon(data) {
    return request({
        url: managerUrl + "/carton/shop/info/discount/check",
        method: 'post',
        data
    })
}

//套餐列表
export function shopComboList(data) {
    return request({
        url: managerUrl + "/carton/shop/goods/combo",
        method: 'post',
        data
    })
}

//计算订单价格
export function getShopOrderPrice(data) {
    return request({
        url: managerUrl + "/carton/shop/order/compute",
        method: 'post',
        data
    })
}

//创建订单
export function createShopOrder(data) {
    return request({
        url: managerUrl + "/carton/shop/order/create",
        method: 'post',
        data
    })
}

//计算升级订单价格
export function getShopUpgradeOrderPrice(data) {
    return request({
        url: managerUrl + "/carton/shop/order/compute/up",
        method: 'post',
        data
    })
}

//套餐升级商品列表
export function shopUpgradeComboList(data) {
    return request({
        url: managerUrl + "/carton/shop/goods/up",
        method: 'post',
        data
    })
}

//创建升级订单
export function createShopUpgradeOrder(data) {
    return request({
        url: managerUrl + "/carton/shop/order/up",
        method: 'post',
        data
    })
}

//基础套餐时长列表
export function shopBaseTimeList(data) {
    return request({
        url: managerUrl + "/carton/shop/time/list",
        method: 'get',
        data
    })
}

//关闭订单
export function closeShopOrder(data) {
    return request({
        url: managerUrl + "/carton/shop/order/close",
        method: 'get',
        data
    })
}