<template>
  <div class="shop-user dp_f">
    <div class="shop-user-img">
      <img :src="baseInfo.avatar" alt="位元灵感"/>
    </div>
    <div class="user-content">
      <div class="user-name">
        <div class="left">
          <div class="name">{{ baseInfo.userName }}</div>
          <div class="wy-level" v-if="baseInfo.comboUrl">
            <img :src="baseInfo.comboUrl" alt="位元灵感" class="level-img"/>
          </div>
        </div>
        <div class="upLevel cur_p" @click="upLevel" v-if="canUplevel">版本升级</div>
      </div>
      <div class="company">{{ baseInfo.companyName }}</div>
    </div>
  </div>
</template>

<script setup>
import {getCurrentInstance} from "vue";
import {useRoute} from "vue-router";
import {goSomeWhere} from '@views/shop/utils';
import useBaseInfo from "@utils/tool/shop/useBaseInfo";

const {baseInfo, canUplevel} = useBaseInfo()
const route = useRoute();

const {proxy} = getCurrentInstance();
const upLevel = () => {
  if (route.fullPath.indexOf('/shop/order/upLevel') !== -1) {
    return
  }
  goSomeWhere(route, '/shop/order/upLevel')
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "shopUser",
});
</script>

<style lang="scss" scoped>
.shop-user {
  .shop-user-img {
    margin-right: 24px;
    width: 48px;
    height: 48px;
    border-radius: 48px;
  }

  .user-content {
    flex: auto;

    .user-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;

      .left {
        display: flex;

        .name {
          @include textOverflow(100px);
          color: $shopFsColor;
        }

        .wy-level {
          margin-left: 8px;

          .level-img {
            width: 80px;
            height: 24px;
            margin-right: 4px;
          }
        }
      }

      .upLevel {
        color: $mainColor;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .company {
      margin-top: 6px;
      color: $shopFsColor2;
      font-size: 14px;
      @include textOverflow(200px);
    }
  }
}
</style>
