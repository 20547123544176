<template>
  <el-popover
      placement="left-start"
      :width="488"
      trigger="click"
      popper-class="shop-proper"
  >
    <template #reference>
      <slot></slot>
    </template>
    <div class="share-content">
      <h3 class="title">分享邀请好友</h3>
      <!--      <div class="share-sub-title">被邀请人购买满一年，邀请人与被邀请人均<span class="bule">可额外获赠一个月</span></div>-->
      <div class="share-box ">
        <div class="text">通过链接邀请</div>
        <div class="link dp_f">
          <div class="l">{{ url }}</div>
          <div class="r">邀请码：{{ baseInfo.mcode }}</div>
        </div>
        <div class="btn-box mt_32">
          <button type="button" class="wy-button primary" @click="handleCopy">复制链接及邀请码</button>
        </div>
      </div>
    </div>
  </el-popover>
</template>

<script setup>
import {computed, getCurrentInstance} from "vue";
import {copyText} from "@utils/tool.js";
import {useStore} from "vuex";

const url = window.location.host
const baseInfo = computed(() => store.state.baseic)
const store = useStore()
const {proxy} = getCurrentInstance();
const handleCopy = () => {
  const str = url + '/register?inviteCode=' + baseInfo.value.mcode
  copyText(str)
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "share",
});

</script>

<style lang="scss" scoped>
.shop-proper {
  .bule {
    color: $mainColor;
  }
  .share-content {
    padding: 30px 42px;

    .title {
      font-size: 20px;
      color: $shopFsColor;
      line-height: 1;
    }

    .share-sub-title {
      line-height: 1;
      color: $shopFsColor2;
      margin-top: 12px;
    }

    .share-box {
      margin-top: 24px;
      border-top: 1px dashed $shopBorderColor;
      padding-top: 24px;

      > div {
        line-height: 1;
      }

      .text {
        font-size: 16px;
        color: #161829;
      }

      .link {
        margin-top: 12px;
        padding: 10px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid $shopBorderColor;

        div {
          color: $shopFsColor2;
          font-size: 14px;
        }

        .r {
          border-left: 1px solid $shopBorderColor;
          padding-left: 16px;
        }
      }

      .btn-box {
        text-align: center;
      }
    }
  }
}
</style>
