<template>
  <ul class="user-menu">
    <li v-for="(item,index) in menuList" class="item cur_p" @click="go(item)" :key="item.id"
        :class="[item.required?'isRequired':'',tabChecked === index ?'active':'']">
      <span v-if="item.id !== 5">{{ item.name }}</span>
      <span v-else>
       <share>
          <span>{{ item.name }}</span>
       </share>
      </span>
    </li>
  </ul>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {goSomeWhere} from '@views/shop/utils';
import {ElMessageBox} from "element-plus";
import {removeToken} from "@utils/tool/token";
import Share from "@views/shop/components/shopHeader/components/share.vue";

const route = useRoute();
const router = useRouter();
const menuList = ref([
  {
    name: "个人中心",
    jumpUrl: "/shop/person/index",
    id: 1,
  },
  {
    name: "订单管理",
    jumpUrl: "/shop/person/order",
    id: 2,
  },
  {
    name: "分享有礼",
    jumpUrl: "/home",
    id: 5,
  },
  {
    name: "进入管理平台",
    jumpUrl: "/home",
    id: 4,
  },
  {
    name: "图文教程",
    jumpUrl: "/shop/graphicCourse",
    id: 6,
  },
  {
    name: "退出登录",
    //重要
    required: true,
    jumpUrl: "/shop/login",
    id: 3,
  },
])
const tabChecked = computed(() => {
  return menuList.value.findIndex(item=> item.jumpUrl === route.fullPath)
})
const {proxy} = getCurrentInstance();
const go = (item) => {
  if (item.id === 5) {
    return
  }
  if (item.id !== 3) {
    goSomeWhere(route, item.jumpUrl)
  } else {
    ElMessageBox.confirm('是否退出登录？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      customClass: "zh-message-box shop",
    }).then(() => {
      proxy.$message.success("退出成功");
      proxy.$cookies.remove("userInfo");
      removeToken()
      if (route.fullPath === '/') {
        location.reload()
      } else {
        router.push({
          path: "/",
        });
      }
    }).catch(() => {
      // do nothing
    });
  }
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "userMenu",
});
</script>

<style lang="scss" scoped>
.user-menu {
  color: $shopFsColor;
  font-size: 14px;
  .item {
    line-height: 1;
    &.active{
      color: $mainColor;
    }
    span{
      cursor: pointer !important;
    }
    &.isRequired {
      color: $errColor;
    }

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
}
</style>
