<template>
  <div>
    <el-popover
        placement="bottom-end"
        :width="400"
        transition="el-zoom-in-top"
        trigger="click"
        popper-class="shop-proper"
        popper-style=""
    >
      <template #reference>
        <div class="shop-user-control cur_p">
          <div class="shop-user-img">
            <img :src="baseInfo.avatar" alt="位元灵感"/>
          </div>
        </div>
      </template>
      <div class="shop-user-info">
        <div class="top">
          <shop-user class="shop-user"></shop-user>
          <member-time :base-info="baseInfo" class="vip-time"></member-time>
        </div>
        <div class="bottom">
          <user-menu></user-menu>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted} from "vue";
import ShopUser from "@views/shop/components/shopUser/index.vue";
import MemberTime from "@views/shop/components/shopUser/memberTime.vue";
import UserMenu from "@views/shop/components/shopUser/userMenu.vue";
import {setBaseUserInfo} from '@utils/tool/getUserInfo.js'
import {useStore} from "vuex";

const store = useStore()
const {proxy} = getCurrentInstance();
const baseInfo = computed(() => store.state.baseic)
onMounted(()=>{
  setBaseUserInfo()
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "userControl"
});
</script>

<style lang="scss" scoped>


.shop-user-control {
  display: flex;
  align-items: center;
  justify-content: center;
}

.shop-user-info {
  border-radius: 8px;
  overflow: hidden;

  .top {
    background: linear-gradient(90deg, #EBEFFF 0%, #F5F7FF 100%);
    padding: 20px 24px 8px 24px;

    .shop-user {
      padding-bottom: 16px;
    }

    .vip-time {
      border-top: 1px dashed #fff;
      padding-top: 8px;
    }
  }

  .bottom {
    padding: 30px 24px;
  }
}

</style>
