<template>
  <div class="shop-header" :style="style">
    <div class="header">
      <ShopLogo :type="type" :scroll-top="scrollTop"></ShopLogo>
      <header-control :type="type"></header-control>
    </div>
  </div>
</template>

<script setup>
import {computed, getCurrentInstance, ref, toRefs} from "vue";
import ShopLogo from "../logo.vue";
import HeaderControl from "../headerControl.vue";
import {useEventListener} from "@utils/tool/event";
//获取滚动条的offsetTop
let scrollTop = ref(0);
const scroll = () => {
  scrollTop.value = (document.documentElement.scrollTop / 1000);
};
const style = computed(() => {
  return {
    backgroundColor: `rgba(56,89,255,${scrollTop.value})`,
  };
});
useEventListener(window, "scroll", scroll);
const props = defineProps({
  type: {
    type: String,
    default: "index",
  },
})
const {type} = toRefs(props);
const {proxy} = getCurrentInstance();
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "shopHeader",
});
</script>

<style lang="scss" scoped>
.shop-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 16px 30px;
    color: #3859FF;
    max-width: 1660px;
    flex-wrap: wrap;
  }
}
</style>
