<template>
  <div class="back-btn cur_p" v-bind="attrs" @click="goIndex">首页</div>
</template>

<script setup>
import {getCurrentInstance, useAttrs} from "vue";
import {useRoute, useRouter} from "vue-router";
import {goSomeWhere} from '@views/shop/utils';

const router = useRouter();
const route = useRoute();
const imgUrl = require('@img/shop/go-back.png');
const attrs = useAttrs()
const {proxy} = getCurrentInstance();
const goIndex = () => {
  goSomeWhere(route, '/')
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "goBackBtn",
});
</script>

<style lang="scss" scoped>
.back-btn {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  width: max-content;
  user-select: none;

  img {
    margin-left: 8px;
    width: 22px;
    height: 6px;
  }
}
</style>
