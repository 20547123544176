<template>
  <el-popover
      placement="bottom-end"
      :width="400"
      transition="el-zoom-in-top"
      trigger="click"
      popper-class="shop-proper"
      popper-style=""
  >
    <template #reference>
      <div class="index-user">
        <img src="@img/shop/index/index-user.png" alt="位元灵感"/>
      </div>
    </template>
    <div class="shop-user-info">
      <div class="top">
        <shop-user :baseInfo="baseInfo" class="shop-user"></shop-user>
        <member-time :base-info="baseInfo" class="vip-time"></member-time>
      </div>
      <div class="bottom">
        <user-menu></user-menu>
      </div>
    </div>
  </el-popover>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted} from "vue";
import UserMenu from "@views/shop/components/shopUser/userMenu.vue";
import ShopUser from "@views/shop/components/shopUser/index.vue";
import MemberTime from "@views/shop/components/shopUser/memberTime.vue";
import {setBaseUserInfo} from '@utils/tool/getUserInfo.js'
import {useStore} from "vuex";

const store = useStore()
const {proxy} = getCurrentInstance();
const baseInfo = computed(() => store.state.baseic)
onMounted(()=>{
  setBaseUserInfo()
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "indexUser",
});
</script>

<style lang="scss" scoped>
.index-user {
  width: 36px;
  height: 36px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 36px;
  overflow: hidden;
  cursor: pointer;
  img {
    width: 36px;
    height: 36px;
  }
}

.shop-user-info {
  border-radius: 8px;
  overflow: hidden;

  .top {
    background: linear-gradient(90deg, #EBEFFF 0%, #F5F7FF 100%);
    padding: 20px 24px 8px 24px;

    .shop-user {
      padding-bottom: 16px;
    }

    .vip-time {
      border-top: 1px dashed #fff;
      padding-top: 8px;
    }
  }

  .bottom {
    padding: 30px 24px;
  }
}

</style>
