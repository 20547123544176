<template>
  <shop-pop>
    <div class="index-buy">
      <img :src="imgUrl" alt="位元灵感"/>
      <span class="buy-text">购买</span>
    </div>
    <template #content>
      <ul class="shop-menu">
        <li class="item cur_p" @click="buyPackage">购买套餐</li>
        <li class="item cur_p" @click="buyGoods">购买耗材</li>
      </ul>
    </template>
  </shop-pop>
</template>

<script setup>
import {computed, getCurrentInstance, toRefs} from "vue";
import {useRouter} from "vue-router";
import ShopPop from "@/views/shop/components/shopPop/index.vue";
import useBaseInfo from "@utils/tool/shop/useBaseInfo";

const {goBuy} = useBaseInfo()
const router = useRouter();
const {proxy} = getCurrentInstance();
const props = defineProps({
  type: {
    type: String,
    default: "index",
  },
})
const imgUrl = computed(() => {
  if (type.value === 'index') {
    return require('@img/shop/index/buy-btn.png');
  }else if(type.value === 'other'){
    return require('@img/shop/index/buy-btn.png');
  } else {
    return require('@img/shop/index/buy-btn2.png')
  }

})
const {type} = toRefs(props);
const buyGoods = () => {
  router.push({
    path: "/shop/goodsList",
  })
}
//购买套餐
const buyPackage = () => {
  goBuy()
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "indexBuyBtn",
});
</script>

<style lang="scss" scoped>
.index-buy {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  position: relative;

  img {
    margin-right: 8px;
    width: 14px;
    height: 16px;
  }

}

.shop-menu {
  padding: 28px 0;

  .item {
    color: #fff;
    font-size: 14px;
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 36px;
    }
  }
}

</style>