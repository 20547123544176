<template>
  <div class="shop-header-control" v-if="type === 'index' ">
    <template v-if="getToken()">
      <index-buy-btn :type="type" class="buy-btn"></index-buy-btn>
      <concat class="learn"></concat>
      <go-manager class="learn"/>
      <index-user></index-user>
    </template>
    <template v-else>
      <go-manager class="learn"/>
      <login-btn/>
    </template>
  </div>
  <div class="shop-header-control" :class="type" v-else>
    <template v-if="getToken()">
      <go-back class="back-btn"></go-back>
      <index-buy-btn :type="type" class="buy-btn"></index-buy-btn>
      <concat class="learn"></concat>
      <go-manager class="learn"/>
      <user class="user-info"></user>
    </template>
    <template v-else>
      <go-back class="back-btn"></go-back>
      <concat class="learn"></concat>
      <go-manager class="learn"/>
      <login-btn/>
    </template>
  </div>
</template>

<script setup>
import {computed, getCurrentInstance, toRefs} from "vue";
import GoBack from "@views/shop/components/goBack.vue";
import User from "@views/shop/components/user.vue";
import LoginBtn from "@views/shop/components/shopHeader/components/loginBtn.vue";
import IndexBuyBtn from "@views/shop/components/shopHeader/components/indexBuyBtn.vue";
import IndexUser from '@views/shop/components/shopHeader/components/user.vue'
import Concat from "@views/shop/components/shopHeader/components/concat.vue";
import GoManager from "@views/shop/components/shopHeader/components/goManager.vue";
import {useRoute} from "vue-router";
import {getToken} from "@utils/tool/token";

const route = useRoute();
const showGoodsList = computed(() => route.path !== "/shop/goodsList");
const props = defineProps({
  type: {
    type: String,
    default: "index",
  },
})
const {type} = toRefs(props);
const {proxy} = getCurrentInstance();
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "shopHeaderControl",
});
</script>

<style lang="scss" scoped>
.shop-header-control {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &.graphicCourse {
    .back-btn {
      color: $shopFsColor;
    }

    .learn {
      color: $shopFsColor;
    }

    .buy-btn {
      color: $shopFsColor;

      :deep(.buy-text) {
        color: $shopFsColor;
      }
    }
  }
  .back-btn {
    margin-right: 80px;
  }
  .learn {
    margin-right: 80px;
  }

  .buy-btn {
    margin-right: 80px;
  }
  .user-info {
    margin-left: 40px;
  }
}
</style>
