<template>
  <div class="manager-btn" v-bind="attrs" @click="goIndex">进入管理平台</div>
</template>

<script setup>
import {getCurrentInstance, useAttrs} from "vue";
import {useRoute, useRouter} from "vue-router";
import {goSomeWhere} from '@views/shop/utils';

const attrs = useAttrs()
const router = useRouter();
const route = useRoute();
const {proxy} = getCurrentInstance();
const goIndex = () => {
  goSomeWhere(route, '/home')
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "goodsListBtn",
});
</script>

<style lang="scss" scoped>
.manager-btn {
  color: #fff;
  cursor: pointer;
  font-size: 14px;
}
</style>
